import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { TRACK_EVENT } from "supercell-frontend-base/src/constants/types";
import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";
import { useSnowplowContext } from "supercell-frontend-base/src/analytics/snowplow";

import { filterLiveArticles } from "supercell-frontend-base/src/utils/targeting/filter-live-articles";

import { CommunityView } from "./view";

export const CommunityController = () => {
    const { pathname: pagePath } = useLocation();
    const sendCommand = useSendCommand();
    const { pageView } = useSnowplowContext();

    useEffect(() => {
        pageView("Community");
        sendCommand(TRACK_EVENT, {
            event: "Pageview",
            pagePath,
            pageTitle: "community",
        });

        window.history.pushState({ home: true }, undefined);
    }, []);

    return <CommunityView filterArticles={filterLiveArticles} />;
};
