import React from "react";

import { Grid } from "supercell-frontend-base/src/components/grid";
import { List } from "supercell-frontend-base/src/components/list";
import { ListArticle } from "supercell-frontend-base/src/components/list/article";
import { Tab } from "supercell-frontend-base/src/components/inbox/tab";

import { communityParser } from "supercell-frontend-base/src/parsers/community";

import styles from "./styles.modules.scss";

export const CommunityView = ({ filterArticles }) => (
    <Tab namespace="community" parser={communityParser}>
        {({ data: { articles } }) => (
            <div className={`community ${styles.community}`}>
                <Grid>
                    <div className={`articles ${styles.articles}`}>
                        <List
                            Item={ListArticle}
                            items={filterArticles(articles)}
                            columns={1}
                            perPage={4}
                            footer={null}
                        />
                    </div>
                </Grid>
            </div>
        )}
    </Tab>
);
